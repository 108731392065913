.HizmetlerimizCard {
    width: 350px;
    height: 322px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
    justify-content: center;
    align-items: start;
    padding-left: 20px;
}



.HizmetlerimizCard h1 {
    color: #05BB75;
    font-size: 22px;
    margin-top: 10px;
}

.HizmetlerimizCard p {
    color: #212C4FB2;
    font-size: 16px;
    margin-top: 20px;
}

.HizmetlerimizCard img{

    transform: 0;
}

.HizmetlerimizCard img:hover {
transform:  rotate(65deg)  translate(52px , 23px) ; 

transition-duration: 3s;
}



.HizmetlerimizCard {
    box-shadow: 2px 2px 2px 2px rgba(242, 232, 232, 0.29);

}

.HizmetlerimizCard:hover img{
    
    transform:  rotate(65deg)  translate(52px , 23px) ; 

    transition-duration: 3s;

}

.HizmetlerimizCard:hover {
    box-shadow: 2px 2px 2px 2px #05bb7525;
 

}


