.Comment {
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.CommentTitleDiv {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CommentTitleDiv h1 {
    color: #212C4F;
    font-size: 36px;
    text-align: center;
}

.CommentTitleDiv h3 {
    margin-top: 20px;
    color: #212C4FB2;
    font-size: 22px;
    text-align: center;
}

.CommentCardContainer {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
}

.nocontextDiv {
    height: 100px;
}

@media (max-width: 768px) {
    .CommentCardContainer {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media (max-width: 480px) {
    .CommentTitleDiv h1 {
        font-size: 28px;
    }

    .CommentTitleDiv h3 {
        font-size: 18px;
    }
}
