.Footer {
    background-color: #212C4F;
    height: auto;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row; 
    justify-content: space-around;
    align-items: center;
    color: white;
    gap: 20px;
    padding: 20px 0;
}

.footerH1 {
    font-size: 20px;
    text-align: center;
}

.footerContentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
}

.footerContentDiv .InstaIcon {
    width: 40px;
    height: 40px;
}

.footerLineDiv {
width: 2px;
height: 100px;
background-color: white;
}

.telifDiv {
    background-color: #212C4F;
    text-align: center;
    color: white;
    padding-bottom: 20px;
}

.footerContentDiv a {
    text-decoration: none;
    color: white;
}


@media screen and (max-width: 768px) {
    .Footer {
        flex-direction: column; 
        padding: 10px 0;
    }

    .footerContentDiv {
        row-gap: 15px;
    }

    .footerH1 {
        font-size: 16px;
    }

    .footerContentDiv .InstaIcon {
        width: 30px;
        height: 30px;
    }
}

@media screen and (max-width: 480px) {

    .Footer {

        display: flex;
        flex-direction: column;
        height: fit-content;
    }

    .footerLineDiv {
        display: none;
    }
    .footerH1 {
        font-size: 14px;
    }

    .footerContentDiv {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .footerContentDiv .InstaIcon {
        width: 25px;
        height: 25px;

    }

    .Footer {
        padding: 10px;
    }

    .telifDiv {
        font-size: 14px;
        margin-top: -1px;
    }
}
