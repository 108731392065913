.commentCardContenxDiv {
  background-color: white;
  width: 330px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19px;

 
}

.commentCardMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}



.commentCardContenxDiv h1 {
    font-size: 20px;
    color: #05BB75;
    margin-top: 40px;

}

.commentCardContenxDiv p {
    font-size: 14px;
    color:#212C4FB2 ;
    text-align: center;
    margin-top: 30px;
}

.CommentCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.CommentCard {
    box-shadow: 2px 2px 2px 2px rgba(242, 232, 232, 0.29);

}

.CommentCard:hover {
    box-shadow: 2px 2px 2px 2px #05bb7525;
    animation: heartbeat 2s ease-in-out;


}

.commentCardMain h1 {
font-size: 20px;
}

@keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }