.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  color: black;
  background-color: #fafafa;
}

.navbar a {
  outline: none;
  border: none;
  text-decoration: none;
  color: black;

}

.navbar-logo h1 {
  font-size: 36px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
}

.navbar-logo h1 h2 {
  font-size: 18px;
}

.navbar-menu ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  gap: 40px;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  font-size: 16px;
  position: relative; 
  overflow: hidden; 
}



.navbar-menu li::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px; 
  background-color: #4CAF50; 
  transition: width 1s ease-in-out;
}



.navbar-menu li:hover::after {
  width: 100%; 
}


.navbar-button {
  background-color: #4CAF50;
  border: none;
  padding: 12px;
  cursor: pointer;
  border-radius: 100px;
  color: white;
}

.navbar-button:hover {
  background-color: #45a049;
}

.menu-icon {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.line {
  width: 25px;
  height: 3px;
  background-color: black;
  transition: 0.4s;
}

.line1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.line2 {
  opacity: 0;
}

.line3 {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 768px) {
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 50px;
    color: black;
    background-color: #fafafa;
  }
  .navbar-menu ul {
    display: none;
  }

  .navbar-menu .nav-links-mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #fafafa;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px 0;
  }

  .navbar-menu li {
   display: none;
  }

  .navbar-logo h1 {
    font-size: 36px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 13px;
  }
  
}
