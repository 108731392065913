.HappyCustomer {
    background-image: url(../../Assets/customerBg.png);
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; 
    width: 100%;
    height: 350px;
    background-color: #05BB75;
    overflow: hidden;
}

.happy {
    overflow-x: hidden;

}

.happyCustomerTitleDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    color: white;
}

.happyCustomerTitleDiv h1 {
    font-size: 60px;
}

.happyCustomerList ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    color: white;
    margin-top: 30px;
}

.happyCustomerList ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.counter {
    opacity: 0;
    transition: opacity 0.5s ease-in;
  }
  
  .counter-visible {
    opacity: 1;
  }

.linediv {
    height: 80px;
    background-color: #fafafa;
}

@media (max-width: 768px) {
    .HappyCustomer {
        height: auto;
        padding: 20px;
    }

    .happyCustomerTitleDiv h1 {
        font-size: 40px;
    }

    .happyCustomerList ul {
        flex-direction: column;
        gap: 20px;
    }

    .happyCustomerList ul li h1 {
        font-size: 36px;
    }

    .happyCustomerList ul li h4 {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .happyCustomerTitleDiv h1 {
        font-size: 30px;
    }

    .happyCustomerTitleDiv h3 {
        font-size: 20px;
        text-align: center;
    }

    .happyCustomerList ul li h1 {
        font-size: 28px;
    }

    .happyCustomerList ul li h4 {
        font-size: 16px;
        text-align: center;
    }
}
