*{ 
  margin: 0;
  font-family: "Montserrat";
  scroll-behavior: smooth;

}

:root{

  --deg : 180deg


}