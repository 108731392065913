.Hizmetlerimiz {
    background-color: #fafafa;
}

.HizmetlerimizTitleDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.HizmetlerimizTitleDiv h1 {
    margin-top: 70px;
    font-size: 36px;
    font-weight: bold;
}

.HizmetlerimizTitleDiv p {
    color: #212C4FB2;
    font-size: 18px;
    width: 648px;
    margin-top: 20px;
}

.hizmetlerimizContainer {
    display: grid;
    grid-template-columns: repeat(3, 0);
    align-items: center;
    justify-items: center;
    margin-top: 30px;
    row-gap: 30px;
    column-gap: 430px;
}

.onur {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1200px) {
    .hizmetlerimizContainer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
}


@media (max-width: 480px) {

    .HizmetlerimizTitleDiv h1 {
        margin-top: 70px;
        font-size: 25px;
        font-weight: bold;

    }
    .HizmetlerimizTitleDiv p {
        text-align: center;
        color: #212C4FB2;
        font-size: 14px;
        width: 648px;
        max-width: 350px;
        margin-top: 20px;
    }
}


