.Referanslarımız {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #fafafa;
    position: relative;
    padding: 20px 0;
  }
  
  .referansDiv {
    position: absolute;
    top: 1px;
    background-color: white;
    width: 1000px;
    height: 90px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    box-shadow: 2px 2px 2px rgba(242, 232, 232, 0.29);
  }
  
  .referansDiv img {
    max-height: 60px;
    object-fit: contain;
    transition: transform 0.3s;
  }
  
  .referansDiv img:hover {
    transform: scale(1.1);
  }
  
  /* Responsive styling for mobile screens */
  @media (max-width: 768px) {
    .referansDiv {
      width: 90%;
      height: auto;
      flex-wrap: wrap;
      padding: 15px;
      box-shadow: none;
    }
  
    .referansDiv img {
      max-height: 40px;
      margin: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .referansDiv img {
      max-height: 30px;
      margin: 8px;
    }
  }
  