.BizKimiz {
    display: flex;
    margin-top: 150px;
    justify-content: space-between;
    padding: 10px 50px;
}

.BizKimizLeftDiv {
    flex: 1;
    padding-right: 20px;
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.BizKimizRightDiv {
    flex: 1;
    display: flex;
    justify-content: center;
}

.BizKimizRightDiv img {
    max-width: 100%;
    height: auto;
    animation: floatX 5s ease-in-out infinite;
}

.slide-in-visible {
    opacity: 1;
    transform: translateX(0);
}

@keyframes floatX {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-20px);
    }
}

.BizKimizLeftDiv h3 {
    font-size: 18px;
}

.BizKimizLeftDiv h3 span {
    color: #FFCD00;
}

.BizKimizLeftDiv h1 {
    margin-top: 15px;
    font-size: 36px;
}

.BizKimizLeftDiv h1 span {
    color: #05BB75;
}

.BizKimizLeftDiv p {
    margin-top: 27px;
    font-size: 18px;
    line-height: 1.5;
    color: #212C4FB2;
}

.BizKimizLeftDiv p span {
    color: #05BB75;
}

/* Responsive styling for mobile screens */
@media (max-width: 768px) {
    .BizKimiz {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .BizKimizLeftDiv, .BizKimizRightDiv {
        width: 100%;
        text-align: center;
        padding: 0 15px;
    }

    .BizKimizLeftDiv {
        margin-bottom: 20px;
    }

    .BizKimizLeftDiv h1 {
        font-size: 28px;
    }

    .BizKimizLeftDiv p {
        width: 100%;
        font-size: 16px;
        line-height: 1.4;
    }

    .BizKimizRightDiv img {
        max-width: 80%;
    }
}
