.HomePage {
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;
    flex-wrap: wrap;
    background-color: #fafafa;
    padding-bottom: 100px;
 
  }
  
  .HomePageLeftDiv {
    margin-top: 150px;
    flex: 1;
    opacity: 0;
    transform: translateX(-50px);
    animation: slideInLeft 1s ease-out forwards;
  }
  
  .HomePageLeftDiv h3 {
    font-size: 18px;
  }
  
  .HomePageLeftDiv h3 span {
    color: #ffcd00;
  }
  
  @keyframes slideInLeft {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .HomePageLeftDiv h1 {
    width: 100%;
    font-size: 36px;
    color: #212C4F;
    line-height: 48px;
    font-weight: 900;
    margin-top: 10px;
  }
  
  .HomePageLeftDiv p {
    margin-top: 10px;
    color: #212C4FB2;
    width: 498px;
    line-height: 25px;
  }
  
  .HomePageLeftDiv button {
    background-color: #ff6464;
    color: white;
    border: none;
    border-radius: 100px;
    margin-top: 20px;
    padding: 7px 20px;
  }
  
  .HomePageLeftDiv button:hover {
    background-color: #f84343;
    padding: 15px 25px;
    transition-duration: .2s;
  }
  
  .HomePageRightDiv {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .HomePageRightDiv img {
    max-width: 100%;
    height: auto;
    animation: float 2s ease-in-out infinite;
  }
  
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-30px);
    }
  }
  
  /* Responsive styling for mobile screens */
  @media (max-width: 768px) {
    .HomePage {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  
    .HomePageLeftDiv {
      margin-top: 20px;
      text-align: center;
      opacity: 1;
      transform: translateX(0);
      animation: none; /* Disable animation on mobile */
    }
  
    .HomePageLeftDiv h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    .HomePageLeftDiv p {
      font-size: 14px;
      width: 100%;
    }
  
    .HomePageLeftDiv button {
      padding: 10px 20px;
      font-size: 16px;
    }
  
    .HomePageRightDiv {
      margin-top: 20px;
    }
  
    .HomePageRightDiv img {
      width: 80%;
      height: auto;
    }
  }
  